<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        id="account-menu-button"
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <app-bar-item to="/customers/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-customer')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/suppliers/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-supplier')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-account-plus-outline</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/products/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-product')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-archive</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/editions/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-edition')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-archive</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/offers/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-offer')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-cart</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/contracts/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-contract')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-file-document-multiple</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/customerContracts/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-customer-contract')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-file-document-multiple</v-icon>
        </v-list-item-icon>
      </app-bar-item>

      <app-bar-item to="/purchases/new">
        <v-list-item-title>{{
          $t('menu.short.resources.new-purchase')
        }}</v-list-item-title>
        <v-list-item-icon class="my-2 align-self-center">
          <v-icon>mdi-credit-card-plus</v-icon>
        </v-list-item-icon>
      </app-bar-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AddResourceMenu'
}
</script>
